<template>
  <div
    class="modal fade"
    id="modalAddGoods"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Barang</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Kategori </label>
              <Select2
                style="width: 100%"
                v-model="formBarang.id_kategori"
                :options="optionCategory"
                placeholder="Pilih Kategori"
                :settings="{
                  allowClear: true,
                  dropdownParent: '#modalAddGoods',
                }"
                @change="barangChange('id_kategori', $event)"
                @select="barangChange('id_kategori', $event)"
                :class="{
                  'is-invalid': formErrorBarang && formErrorBarang.id_kategori,
                }"
              />
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Nama Barang </label>
              <Select2
                style="width: 100%"
                v-model="formBarang.kode_barang"
                :options="optionGoods"
                placeholder="Pilih Barang"
                :settings="{
                  templateResult: resultBarang,
                  dropdownParent: '#modalAddGoods',
                }"
                @change="barangChange('kode_barang', $event)"
                @select="barangChange('kode_barang', $event)"
                :class="{
                  'is-invalid': formErrorBarang && formErrorBarang.kode_barang,
                }"
              />
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Pagu </label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">Rp</div>
                </div>
                <input
                  disabled
                  type="text"
                  v-model="formBarang.pagu"
                  class="form-control text-right"
                  placeholder="Masukkan Pagu"
                />
              </div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Harga </label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">Rp</div>
                </div>
                <input
                  type="text"
                  v-model.lazy="formBarang.harga"
                  v-money="configMoney"
                  @keyup="barangChange('harga', $event)"
                  class="form-control text-right"
                  :class="{
                    'is-invalid': formErrorBarang && formErrorBarang.harga,
                  }"
                  placeholder="Masukkan Harga"
                />
              </div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Satuan </label>
              <div class="input-group">
                <!-- <div class="input-group-prepend">
                  <span
                    class="input-group-text"
                    style="border-radius: 0; background: #e9ecef"
                    >/</span
                  >
                </div> -->
                <input
                  type="text"
                  class="form-control text-right"
                  placeholder="ex. 2000"
                  @keyup="barangChange('satuan_jumlah', $event)"
                  v-maska="'#########'"
                  :class="{
                    'is-invalid':
                      formErrorBarang && formErrorBarang.satuan_jumlah,
                  }"
                  v-model="formBarang.satuan_jumlah"
                />

                <input
                  type="text"
                  class="form-control"
                  placeholder="ex. m2"
                  v-model="formBarang.satuan_nama"
                  :class="{
                    'is-invalid':
                      formErrorBarang && formErrorBarang.satuan_nama,
                  }"
                  @keyup="barangChange('satuan_nama', $event)"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click="saveBarang" class="btn btn-save">
              Tambah Data
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">Edit Vendor</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-vendor">Vendor </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Edit
                  </li>
                </ol>
              </nav>
            </div>
            <div class="button-edit" v-show="!isEditable">
              <button class="btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div v-show="isEditable">
              <button class="btn btn-save" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>
          <div class="card info-detail">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  >Detail Vendor</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pills-profile-tab"
                  data-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  >Contact Vendor</a
                >
              </li>

              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pills-goods-tab"
                  data-toggle="pill"
                  href="#pills-goods"
                  role="tab"
                  aria-controls="pills-goods"
                  aria-selected="false"
                  >Daftar Barang</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pills-history-tab"
                  data-toggle="pill"
                  href="#pills-history"
                  role="tab"
                  aria-controls="pills-history"
                  aria-selected="false"
                  >History Transaksi</a
                >
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div class="row mt-3">
                  <div class="col-12">
                    <div class="title">Detail Vendor</div>
                    <div class="sub-title">Edit informasi yang diperlukan</div>
                  </div>
                </div>
                <hr />
                <div class="row mt-2">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="nama">Nama Perusahaan : </label>
                      <input
                        :disabled="!isEditable"
                        type="text"
                        v-model="formData.nama"
                        @keyup="formChange('nama')"
                        :class="{ 'is-invalid': formError && formError.nama }"
                        class="form-control"
                        id="nama"
                        placeholder="Masukkan Nama Perusahaan"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.nama"
                      >
                        {{ formError.nama }}
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Nomor Handphone :</label>
                      <input
                        :disabled="!isEditable"
                        type="text"
                        v-maska="'############################'"
                        v-model="formData.no_handphone"
                        @keyup="formChange('no_handphone')"
                        :class="{
                          'is-invalid': formError && formError.no_handphone,
                        }"
                        class="form-control"
                        placeholder="Masukkan  Nomor Handphone"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.no_handphone"
                      >
                        {{ formError.no_handphone }}
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Nomor Telepon :</label>
                      <input
                        :disabled="!isEditable"
                        type="text"
                        v-maska="'### #########################'"
                        v-model="formData.no_telp"
                        @keyup="formChange('no_telp')"
                        :class="{
                          'is-invalid': formError && formError.no_telp,
                        }"
                        class="form-control"
                        placeholder="Masukkan Nomor Telepon"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.no_telp"
                      >
                        {{ formError.no_telp }}
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Nomor Whatsapp :</label>
                      <input
                        :disabled="!isEditable"
                        type="text"
                        v-maska="'############################'"
                        v-model="formData.no_whatsapp"
                        @keyup="formChange('no_whatsapp')"
                        :class="{
                          'is-invalid': formError && formError.no_whatsapp,
                        }"
                        class="form-control"
                        placeholder="Masukkan Nomor Whatsapp"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.no_whatsapp"
                      >
                        {{ formError.no_whatsapp }}
                      </div>
                    </div>

                    <!-- <div class="form-group">
                      <label>Rekening :</label>

                      <div class="d-flex flex-row">
                        <div>
                          <Select2
                            placeholder="Pilih Bank"
                            class="align-self-center bank-options"
                            :options="optionBank"
                            style="width: 200px"
                            v-model="formData.nama_bank"
                            :class="{
                              'is-invalid': formError && formError.nama_bank,
                            }"
                            :disabled="!isEditable"
                            @change="myChangeEvent($event)"
                            @select="mySelectEvent($event)"
                          />
                        </div>
                        <input
                          type="text"
                          class="form-control align-self-center"
                          :class="{
                            'is-invalid': formError && formError.nomor_rekening,
                          }"
                          @keyup="formChange('nomor_rekening')"
                          :disabled="!isEditable"
                          v-model="formData.nomor_rekening"
                          placeholder="Masukkan  Nomor Rekening"
                          v-maska="'############################'"
                          style="
                            width: 100%;
                            border-top-left-radius: 0px;
                            border-bottom-left-radius: 0px;
                          "
                        />
                      </div>
                      <div
                        class="form-error"
                        v-if="
                          formError &&
                          (formError.nama_bank || formError.nomor_rekening)
                        "
                      >
                        {{
                          formError.nama_bank
                            ? formError.nama_bank
                            : formError.nomor_rekening
                        }}
                      </div>
                    </div> -->
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label for="email">Email :</label>
                      <input
                        :disabled="!isEditable"
                        type="email"
                        v-model="formData.email"
                        @keyup="formChange('email')"
                        :class="{ 'is-invalid': formError && formError.email }"
                        class="form-control"
                        id="email"
                        placeholder="Masukkan Email"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.email"
                      >
                        {{ formError.email }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label>NPWP :</label>
                      <input
                        :disabled="!isEditable"
                        type="text"
                        v-maska="'##.###.###.#-###.###'"
                        v-model="formData.npwp"
                        @keyup="formChange('npwp')"
                        :class="{ 'is-invalid': formError && formError.npwp }"
                        class="form-control"
                        placeholder="Masukkan NPWP"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.npwp"
                      >
                        {{ formError.npwp }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Kategori :</label>
                      <div class="form-group edit-vendor">
                        <Select2
                          :disabled="!isEditable"
                          v-model="formData.id_kategori"
                          @change="formChange('id_kategori')"
                          @select="formChange('id_kategori')"
                          :class="{
                            'is-invalid': formError && formError.id_kategori,
                          }"
                          :options="OptionsKategori"
                          placeholder="Kategori"
                        />
                        <div
                          class="form-error"
                          v-if="formError && formError.id_kategori"
                        >
                          {{ formError.id_kategori }}
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Tahun Bergabung :</label>
                      <input
                        type="text"
                        v-maska="'####'"
                        :disabled="!isEditable"
                        v-model="formData.tahun_bergabung"
                        @keyup="formChange('tahun_bergabung')"
                        :class="{
                          'is-invalid': formError && formError.tahun_bergabung,
                        }"
                        class="form-control"
                        placeholder="Masukkan Tahun Bergabung"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.tahun_bergabung"
                      >
                        {{ formError.tahun_bergabung }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Status :</label>
                      <div class="form-group edit-vendor">
                        <Select2
                          :disabled="!isEditable"
                          v-model="formData.status"
                          :class="{
                            'is-invalid': formError && formError.status,
                          }"
                          :options="statusCompany"
                          placeholder="Status"
                          :settings="{ minimumResultsForSearch: -1 }"
                          @change="formChange('status')"
                          @select="formChange('status')"
                        />
                        <div
                          class="form-error"
                          v-if="formError && formError.status"
                        >
                          {{ formError.status }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <div class="title">Alamat Vendor</div>
                    <div class="sub-title">
                      Edit alamat client yang diperlukan
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Provinsi :</label>
                      <Select2
                        :disabled="!isEditable"
                        v-model="formData.id_provinsi"
                        :options="OptionsProvince"
                        placeholder="Provinsi"
                        :class="{
                          'is-invalid': formError && formError.id_provinsi,
                        }"
                        @change="locationEvent($event, 'id_provinsi')"
                        @select="locationEvent($event, 'id_provinsi')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.id_provinsi"
                      >
                        {{ formError.id_provinsi }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Kabupaten :</label>
                      <Select2
                        :disabled="!isEditable"
                        v-model="formData.id_kabupaten"
                        :options="OptionsRegion"
                        placeholder="Kabupaten"
                        :settings="{
                          settingOption: value,
                          settingOption: value,
                        }"
                        :class="{
                          'is-invalid': formError && formError.id_kabupaten,
                        }"
                        @change="locationEvent($event, 'id_kabupaten')"
                        @select="locationEvent($event, 'id_kabupaten')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.id_kabupaten"
                      >
                        {{ formError.id_kabupaten }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Kecamatan :</label>
                      <Select2
                        :disabled="!isEditable"
                        v-model="formData.id_kecamatan"
                        :options="OptionsDistrict"
                        placeholder="Kecamatan"
                        :settings="{
                          settingOption: value,
                          settingOption: value,
                        }"
                        :class="{
                          'is-invalid': formError && formError.id_kecamatan,
                        }"
                        @change="locationEvent($event, 'id_kecamatan')"
                        @select="locationEvent($event, 'id_kecamatan')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.id_kecamatan"
                      >
                        {{ formError.id_kecamatan }}
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="lokasi">Alamat :</label>
                      <textarea
                        :disabled="!isEditable"
                        type="text"
                        class="form-control"
                        id="lokasi"
                        @keyup="formChange('alamat')"
                        v-model="formData.alamat"
                        :class="{ 'is-invalid': formError && formError.alamat }"
                        placeholder="Masukkan Alamat"
                      ></textarea>
                      <div
                        class="form-error"
                        v-if="formError && formError.alamat"
                      >
                        {{ formError.alamat }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="title">Rekening Vendor</div>
                    <div class="sub-title">
                      Tambah data rekening vendor yang diperlukan
                    </div>
                  </div>
                </div>
                <hr />

                <div class="row">
                  <div class="col-12">
                    <div class="form-error mb-2" v-if="errorRekening">
                      Masukkan rekening vendor dengan benar
                    </div>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Atas Nama</th>
                          <th style="width: 20%">Bank</th>
                          <th>Nomor Rekening</th>
                          <th style="width: 80px" v-if="isEditable">Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(value, index) in listRekening" :key="index">
                          <td>
                            <input
                              :disabled="!isEditable"
                              type="text"
                              class="form-control"
                              :id="`rekening_nama${index}`"
                              @keyup="formChangeRekening(index)"
                              v-model="listRekening[index].rekening_nama"
                              :class="{
                                'is-invalid':
                                  errorRekening &&
                                  !listRekening[index].rekening_nama,
                              }"
                              placeholder="Masukkan Nomor Rekening"
                            />
                          </td>
                          <td>
                            <Select2
                              :disabled="!isEditable"
                              class="editable"
                              v-model="listRekening[index].rekening_bank"
                              :options="optionBank"
                              placeholder="Bank"
                              :id="`pilihanBank${index}`"
                              :class="{
                                'is-invalid':
                                  errorRekening &&
                                  !listRekening[index].rekening_bank,
                              }"
                              @change="formChangeRekening(index, $event)"
                              @select="formChangeRekening(index, $event)"
                            />
                          </td>
                          <td>
                            <input
                              :disabled="!isEditable"
                              type="text"
                              class="form-control"
                              v-maska="
                                '#############################################'
                              "
                              :id="`rekening_nomor${index}`"
                              :class="{
                                'is-invalid':
                                  errorRekening &&
                                  !listRekening[index].rekening_nomor,
                              }"
                              @keyup="formChangeRekening(index)"
                              v-model="listRekening[index].rekening_nomor"
                              placeholder="Masukkan Nomor Rekening"
                            />
                          </td>
                          <td v-if="isEditable">
                            <div class="d-flex justify-content-center">
                              <img
                                src="/img/avatar/icon-plus.svg"
                                @click="addRekening()"
                                class="btn-add-row"
                                style="cursor: pointer"
                              />
                              <img
                                src="/img/avatar/icon-delete.svg"
                                @click="deleteRekening(index)"
                                v-if="index != 0"
                                class="btn-add-row ml-1"
                                style="cursor: pointer; width: 20px"
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div class="row mt-3">
                  <div class="col-6">
                    <div class="title">Contact Vendor</div>
                    <div class="sub-title">
                      Edit informasi contact vendor yang diperlukan
                    </div>
                  </div>
                  <div class="col-6 align-self-center text-right">
                    <router-link
                      :to="{
                        name: 'ContactVendorDetail',
                        params: { id_vendor: id_vendor },
                      }"
                      type="button"
                      class="btn-add"
                      >+ Tambah
                    </router-link>
                  </div>
                </div>
                <hr />

                <div class="table-responsive">
                  <table
                    class="table table-bordered table-striped table-hover"
                    id="tableDepartment"
                  >
                    <thead>
                      <tr>
                        <th style="width: 15%">Nama</th>
                        <th style="width: 20%">Jabatan</th>
                        <th style="width: 15%">Nomor Telepon</th>
                        <th style="width: 10%">Email</th>
                        <th style="width: 5% !important">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, index) in listContact"
                        :key="index"
                        v-on:click="detail(value.id)"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Klik untuk melihat detail"
                      >
                        <td>{{ value.nama }}</td>
                        <td>{{ value.jabatan ? value.jabatan : "-" }}</td>
                        <td>{{ value.no_telp ? value.no_telp : "-" }}</td>
                        <td>{{ value.email ? value.email : "-" }}</td>
                        <td>
                          <div
                            class="green-bedge"
                            v-if="value.status === 'aktif'"
                          >
                            Aktif
                          </div>
                          <div class="red-bedge" v-else>Nonaktif</div>
                        </td>
                      </tr>
                      <tr v-if="listContact.length == 0">
                        <td colspan="5" class="text-center">Tidak ada data</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-goods"
                role="tabpanel"
                aria-labelledby="pills-goods-tab"
              >
                <div class="d-flex align-items-center mt-3">
                  <div class="mr-auto">
                    <div class="title">Daftar Barang</div>
                    <div class="sub-title">
                      Detail informasi vendor yang diperlukan
                    </div>
                  </div>
                  <button
                    class="btn btn-download d-inline-block mr-2"
                    style="padding: 8px"
                    @click="downloadFormat"
                    type="button"
                    :disabled="onDownload"
                    v-if="isEditable"
                  >
                    <img src="/img/icon-import.svg" alt="" v-if="!onDownload" />
                    <span class="fas fa-spin fa-spinner" v-else></span>
                    Download Format
                  </button>
                  <div class="">
                    <div class="dropdown">
                      <button
                        v-if="isEditable"
                        class="btn btn-add dropdown-toggle"
                        type="button"
                        :disabled="onUpload"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span
                          class="fas fa-spinner fa-spin"
                          v-if="onUpload"
                        ></span>
                        Tambah
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          class="dropdown-item"
                          href="javascript:void(0)"
                          @click="addGoods"
                          >Manual</a
                        >
                        <hr style="margin: 0px" />
                        <a
                          class="dropdown-item"
                          href="javascript:void(0)"
                          @click="setFile"
                          >Import</a
                        >
                      </div>
                    </div>
                  </div>
                  <input
                    type="file"
                    ref="fileImport"
                    style="display: none"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    @change="changeFile($event)"
                  />
                </div>
                <hr />
                <div class="mt-4">
                  <table
                    class="table table-bordered table-striped"
                    id="tableGoods"
                  >
                    <thead>
                      <tr>
                        <th style="width: 15%">Kode Barang</th>
                        <th style="width: 20%">Nama Barang</th>
                        <th style="width: 15%">Kategori</th>
                        <th style="width: 15%">Pagu</th>
                        <th style="width: 15%">Harga</th>
                        <th style="width: 15%">Satuan</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(value, index) in listBarang" :key="index">
                        <td>{{ value.kode_barang }}</td>
                        <td>{{ value.nama_barang }}</td>
                        <td>
                          {{ value.nama_kategori }}
                        </td>
                        <td class="text-right">
                          {{ value.pagu ? formatMoney(value.pagu) : 0 }}
                        </td>
                        <td class="text-right">
                          {{ value.harga ? formatMoney(value.harga) : 0 }}
                        </td>
                        <td class="position-relative">
                          {{ `${value.satuan_jumlah} ${value.satuan_nama}` }}

                          <div
                            style="
                              position: absolute;
                              right: -30px;
                              top: 50%;
                              transform: translateY(-50%);
                            "
                          >
                            <img
                              src="/img/avatar/icon-delete.svg"
                              @click="deleteBarang(index)"
                              v-if="isEditable"
                              class="btn-add-row ml-1"
                              style="cursor: pointer; width: 20px"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr v-if="listBarang.length == 0">
                        <td colspan="6" class="text-center">Tidak ada data</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-history"
                role="tabpanel"
                aria-labelledby="pills-history-tab"
              >
                <div class="row mt-3">
                  <div class="col-6">
                    <div class="title">Transaksi History</div>
                    <div class="sub-title">
                      Edit informasi client yang diperlukan
                    </div>
                  </div>
                </div>
                <hr />
                <div class="table-responsive">
                  <table class="table table-bordered table-striped table-hover">
                    <thead>
                      <tr>
                        <th style="width: 15%">Tanggal</th>
                        <th style="width: 20%">No. Transaksi</th>
                        <th style="width: 15%">Project</th>
                        <th style="width: 10%">Keterangan</th>
                        <th style="width: 15%">Mutasi Kr</th>
                        <th style="width: 15%">Mutasi Dr</th>
                        <th style="width: 15%">Saldo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in listRiwayat" :key="index">
                        <td>{{ formatDate(item.tanggal, 'DD MMMM YYYY') }}</td>
                        <td>{{ item.nomor }}</td>
                        <td>{{ item.nama_project }}</td>
                        <td>{{ item.keterangan ? item.keterangan : '-' }}</td>
                        <td>{{ item.type == 'kredit' ? formatMoney(item.nilai)  : '0'}}</td>
                        <td>{{ item.type == 'debit' ? formatMoney(item.nilai)  : '0'}}</td>
                        <td>{{ formatMoney(item.saldo) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import {
  get_ListBank,
  get_ListKabupaten,
  get_ListKecamatan,
  get_SatuanList,
  get_ListProvinsi,
} from "../../../actions/master";
import {
  get_DetailVendor,
  get_downloadFormatVendor,
  get_ListKategoriVendor,
  post_SaveVendor,
} from "../../../actions/vendor";
import { checkRules, cksClient, showAlert } from "../../../helper";
import { maska } from "maska";
import { VMoney } from "v-money";
import { get_BarangList } from "../../../actions/barang";
import { get_KategoriBarangList } from "../../../actions/barang/kategori";
import { DATA } from "../../../constans/Url";
import readXlsxFile from "read-excel-file";
import moment from "moment";
import "moment/locale/id";
// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    // TableLite
  },

  directives: {
    maska,
    money: VMoney,
  },

  data() {
    return {
      showButton: false,
      isEditable: false,
      statusCompany: [
        { id: "aktif", text: "Aktif" },
        { id: "tidak", text: "Nonaktif" },
      ],
      optionPeriode: ["1 Bulan", "2 Bulan"],
      OptionsKategori: [],
      optionBank: [],
      OptionsDistrict: [],
      OptionsRegion: [],
      OptionsProvince: [],
      optionCategory: [],
      optionGoods: [],
      optionUnit: [],
      codeGoods: "9900012",
      placeholder: [
        {
          text: "kecamatan",
        },
      ],
      id_vendor: this.$route.params.id ? this.$route.params.id : "",
      id_company: cksClient().get("_account").id_company,
      isSubmit: false,
      onDownload: false,
      formData: {
        id: "",
        id_kategori: "",
        id_company: cksClient().get("_account").id_company,
        nama: "",
        alamat: "",
        id_provinsi: "",
        id_kecamatan: "",
        id_kabupaten: "",
        tahun_bergabung: "",
        status: "aktif",
        no_telp: "",
        no_handphone: "",
        no_whatsapp: "",
        email: "",
        npwp: "",
        rekening: [],
      },
      formError: [],
      rules: {
        nama: {
          required: true,
        },
        id_kategori: {
          required: true,
        },
        alamat: {
          required: true,
        },
        id_provinsi: {
          required: true,
        },
        id_kecamatan: {
          required: true,
        },
        id_kabupaten: {
          required: true,
        },
        no_handphone: {
          required: true,
        },
        tahun_bergabung: {
          required: true,
        },
        email: {
          email: true,
          required: true,
        },
      },
      listContact: [],

      //barang
      formBarang: {
        kode_barang: "",
        nama_barang: "",
        nama_kategori: "",
        pagu: "0",
        harga: "0",
        satuan_jumlah: "",
        satuan_nama: "",
        id_kategori: "",
      },
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      rulesBarang: {
        kode_barang: {
          required: true,
        },
        satuan_jumlah: {
          required: true,
        },
        satuan_nama: {
          required: true,
        },
      },
      deleteBarangArr: [],
      formErrorBarang: [],
      listBarang: [],
      onUpload: false,
      onAddGoods: false,
      importSchema: {
        "KODE BARANG": {
          prop: "kode_barang",
          type: String,
          required: true,
        },
        "NAMA BARANG": {
          prop: "nama_barang",
          type: String,
          required: true,
        },
        HARGA: {
          prop: "harga",
          type: Number,
          required: true,
        },
        "SATUAN JUMLAH": {
          prop: "satuan_jumlah",
          type: Number,
          required: true,
        },
        "NAMA SATUAN": {
          prop: "satuan_nama",
          type: String,
          required: true,
        },
      },
      listRekening: [
        {
          id: "",
          rekening_nama: "",
          rekening_bank: "",
          rekening_nomor: "",
        },
      ],
      errorRekening: false,
      listRiwayat: [],
    };
  },

  created() {
    this.getVendor();
    this.getKategori();
    this.getProvinsi();
    this.getCategoryGoods();
    this.getGoods();
    this.getBank();
    this.getUnit();
  },

  mounted() {
    $(".tab-pane.fade").removeClass("active show");
    if (localStorage.getItem("setContact")) {
      setTimeout(() => {
        localStorage.removeItem("setContact");
        $("#pills-profile-tab").tab("show");
        $("#pills-profile").addClass("active show");
        this.hideButton(true);
      }, 600);
    } else {
      setTimeout(() => {
        $("#pills-home-tab").tab("show");
        $("#pills-home").addClass("active show");
      }, 600);
    }

    // $("#tableGoods").DataTable({
    //   info: false,
    // });

    this.tooltip();
  },
  unmounted() {
    $("#pills-home-tab").tab("show");
  },
  methods: {
    hideButton(data) {
      const show = (this.showButton = data);
      if (show == true) {
        this.isEditable = true;
      } else {
        this.isEditable = false;
      }
      // $(".button-edit").addClass("d-none");
    },

    editable() {
      this.isEditable = true;
      this.showButton = true;
    },

    getBank() {
      get_ListBank((res) => {
        this.optionBank = res.list;
      });
    },
    //barang
    getGoods() {
      get_BarangList(
        {
          id_company: this.id_company,
          search: "aktif",
          isAll: true,
        },
        (res) => {
          var { list } = res;
          this.optionGoods = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionGoods.push({
                text: element.nama,
                id: element.kode,
                kategori: element.kategori,
                nama_kategori: element.nama_kategori,
                pagu: element.harga_pagu ? element.harga_pagu : 0,
              });
            }
          }
        }
      );
    },
    getCategoryGoods() {
      get_KategoriBarangList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          var { list } = res;
          this.optionCategory = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionCategory.push({
                id: element.kode,
                text: element.nama,
              });
            }
          }
        }
      );
    },
    getUnit() {
      get_SatuanList((res) => {
        var { list } = res;
        this.optionUnit = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.optionUnit.push({
              id: element.id,
              text: element.nama,
            });
          }
        }
      });
    },
    addGoods() {
      this.formBarang.nama_barang = "";
      this.formBarang.id_kategori = "";
      this.formBarang.kode_barang = "";
      this.formBarang.pagu = "0";
      this.formBarang.harga = "0";
      this.formBarang.satuan_jumlah = "";
      this.formBarang.satuan_nama = "";
      $("#modalAddGoods").modal("show");
    },
    resultBarang(val) {
      var check = this.searchObjectBarang(val.id) ? true : false;
      if (
        (!this.formBarang.id_kategori ||
          this.formBarang.id_kategori == val.kategori) &&
        !check
      ) {
        return val.text;
      } else {
        return null;
      }
    },
    downloadFormat() {
      this.onDownload = true;
      get_downloadFormatVendor(
        {
          id_company: this.id_company,
          id_vendor: this.formData.id,
        },
        (res) => {
          var a = document.createElement("a");
          // var url = window.URL.createObjectURL(new Blob([res]));
          a.href = DATA.BARANG(res.path_download);
          a.download = "Format Barang Vendor.xlsx";
          document.body.append(a);
          a.click();
          a.remove();
          setTimeout(() => {
            this.onDownload = false;
          }, 1000);
          // window.URL.revokeObjectURL(url);
        }
      );
      // window.open(DATA.FORMAT_VENDOR_BARANG(), "_blank");
    },
    searchObjectBarang(what) {
      return this.listBarang.find((element) => element.kode_barang == what);
    },
    async barangChange(key, val) {
      if (this.rulesBarang[key]) {
        this.rulesBarang[key].changed = true;
      }
      if (key === "kode_barang") {
        this.formBarang.nama_barang = val.text;
        this.formBarang.nama_kategori = val.nama_kategori;
        this.formBarang.pagu = this.formatMoney(val.pagu);
        // this.formBarang.id_kategori = val.kategori;
      }
      var check = await checkRules(this.rulesBarang, this.formBarang);
      this.formErrorBarang = check.error;
    },
    deleteBarang(index) {
      this.onAddGoods = true;
      this.listBarang.splice(index, 1);
      // this.deleteBarangArr.push(barang.kode_barang)
    },
    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date, format = 'YYYY-MM-DD') {
      if (date) {
        return moment(date).format(format)
      }
      return '-'
    },
    setFile() {
      this.$refs.fileImport.click();
    },
    async changeFile(event) {
      var files = event.target.files[0];
      var extension =
        event.target.files.length > 0 ? event.target.files[0].type : "";
      if (
        extension ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        setTimeout(() => {
          event.target.value = null;
        }, 500);
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin akan mengimport file ini? jika terdapat kode barang yang sama maka data tersebut akan terupdate!",
          showCancelButton: true,
          confirmButtonText: "Ya, Lanjutkan",
          cancelButtonText: "Batal",
          onSubmit: async () => {
            await readXlsxFile(files, { schema: this.importSchema }).then(
              (row) => {
                var error = row.errors.length;
                console.log(row);
                if (error > 0) {
                  showAlert(this.$swal, {
                    title: "GAGAL!",
                    msg: "Kolom terdapat yang kosong, pastikan semua kolom terisi(KODE BARANG, NAMA BARANG, HARGA, SATUAN JUMLAH DAN NAMA SATUAN)",
                    showCancelButton: true,
                    showConfirmButton: false,
                  });
                } else {
                  this.onAddGoods = true;
                  this.onUpload = true;
                  var checkData = [];
                  var listData = [];
                  for (const key in row.rows) {
                    if (Object.hasOwnProperty.call(row.rows, key)) {
                      const element = row.rows[key];
                      // var checkList = this.searchObjectBarang(val.id);
                      var checkKode = this.optionGoods.find(
                        (el) => el.id == element.kode_barang
                      );
                      if (checkKode && checkKode.id) {
                        var data = {
                          kode_barang: element.kode_barang,
                          nama_barang: checkKode.text,
                          nama_kategori: checkKode.nama_kategori,
                          pagu: checkKode.pagu,
                          harga: element.harga,
                          satuan_jumlah: element.satuan_jumlah,
                          satuan_nama: element.satuan_nama,
                          id_kategori: checkKode.kategori,
                        };
                        listData.push(data);
                      } else {
                        checkData.push(element.kode_barang);
                      }
                    }
                  }
                  if (checkData.length > 0) {
                    this.onUpload = false;
                    showAlert(this.$swal, {
                      title: "GAGAL!",
                      msg: `Kode barang (${checkData.join(
                        ", "
                      )}) tidak ditemukan, periksa kembali kode barang`,
                      showCancelButton: true,
                      showConfirmButton: false,
                    });
                  } else {
                    for (const i in listData) {
                      if (Object.hasOwnProperty.call(listData, i)) {
                        const element = listData[i];
                        var key = this.listBarang.findIndex(
                          (el) => el.kode_barang == element.kode_barang
                        );
                        if (key >= 0) {
                          this.listBarang[key] = element;
                        } else {
                          this.listBarang.push(element);
                        }
                      }
                    }
                    this.onUpload = false;
                  }
                }
              }
            );
          },
        });
      } else {
        event.target.value = null;
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "extension yang diizinkan hanya dengan format .xlsx",
          showCancelButton: true,
          showConfirmButton: false,
        });
      }
    },
    async saveBarang() {
      for (const key in this.rulesBarang) {
        if (Object.hasOwnProperty.call(this.rulesBarang, key)) {
          this.rulesBarang[key].changed = true;
        }
      }
      var check = await checkRules(this.rulesBarang, this.formBarang);
      this.formErrorBarang = check.error;
      if (check.success) {
        this.onAddGoods = true;
        this.listBarang.push({
          kode_barang: this.formBarang.kode_barang,
          nama_barang: this.formBarang.nama_barang,
          nama_kategori: this.formBarang.nama_kategori,
          id_kategori: this.formBarang.id_kategori,
          pagu: this.formBarang.pagu.toString().split(".").join(""),
          harga: this.formBarang.harga.toString().split(".").join(""),
          satuan_jumlah: this.formBarang.satuan_jumlah,
          satuan_nama: this.formBarang.satuan_nama,
        });
        $("#modalAddGoods").modal("hide");
      }
    },

    async getVendor() {
      await get_DetailVendor(this.id_vendor, (res) => {
        const { data, contact, listBarang, list_rekening, riwayat } = res;
        this.getKabupaten(data.id_provinsi);
        this.getKecamatan(data.id_kabupaten);
        this.formData = { ...this.formData, ...data };
        this.listContact = contact;
        if (list_rekening.length > 0) {
          this.listRekening = [];
        }

        this.listRiwayat = riwayat;

        for (const key in res.list_rekening) {
          if (Object.hasOwnProperty.call(res.list_rekening, key)) {
            const element = res.list_rekening[key];
            this.listRekening.push({
              id: element.id,
              rekening_bank: element.bank,
              rekening_nama: element.atas_nama,
              rekening_nomor: element.nomor_rekening,
            });
          }
        }
        // this.listBarang = [];
        // for (const key in listBarang) {
        //   if (Object.hasOwnProperty.call(listBarang, key)) {
        //     var element = listBarang[key];
        //     var satuan = element.satuan.split("/");
        //     delete element.satuan;
        //     element.satuan_jumlah = satuan[0];
        //     element.satuan_nama = satuan[1];
        //     this.listBarang.push(element);
        //   }
        // }
        this.listBarang = listBarang;
      });
    },
    async formChange(key) {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },
    formChangeRekening(index) {
      this.errorRekening =
        !this.listRekening[index].rekening_bank ||
        !this.listRekening[index].rekening_nama ||
        !this.listRekening[index].rekening_nomor;
      if (
        !this.listRekening[index].rekening_bank &&
        !this.listRekening[index].rekening_nama &&
        !this.listRekening[index].rekening_nomor
      ) {
        this.errorRekening = false;
      }
    },
    addRekening() {
      this.listRekening.push({
        id: "",
        rekening_nama: "",
        rekening_bank: "",
        rekening_nomor: "",
      });
    },
    deleteRekening(index) {
      this.listRekening.splice(index, 1);
    },
    myChangeEvent(val) {
      console.log(val);
    },
    async getKategori() {
      await get_ListKategoriVendor(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          var arr = [];
          for (let i = 0; i < list.length; i++) {
            const element = list[i];
            arr.push({
              id: element.id,
              text: element.nama,
            });
          }
          this.OptionsKategori = arr;
        }
      );
    },
    async getProvinsi() {
      await get_ListProvinsi(
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsProvince = arr;
        },
        () => {
          this.OptionsProvince = [];
        }
      );
    },
    async getKabupaten(id) {
      await get_ListKabupaten(
        id,
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsRegion = arr;
        },
        () => {
          this.OptionsRegion = [];
        }
      );
    },

    async getKecamatan(id) {
      await get_ListKecamatan(
        id,
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsDistrict = arr;
        },
        () => {
          this.OptionsDistrict = [];
        }
      );
    },
    locationEvent(val, key) {
      this.formChange(key);
      if (key === "id_provinsi") {
        this.OptionsDistrict = [];
        this.OptionsRegion = [];
        this.getKabupaten(val.id);
      } else if (key === "id_kabupaten") {
        this.OptionsDistrict = [];
        this.getKecamatan(val.id);
      }
    },

    postData() {
      this.isSubmit = true;
      var data = this.formData;
      data.barang = this.listBarang;
      data.rekening = this.listRekening;
      post_SaveVendor(
        data,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code == 201
              ? "Data Vendor berhasil ditambahkan"
              : "Data Vendor berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              if (this.onAddGoods) {
                this.isEditable = false;
                this.onAddGoods = false;
                $("#pills-goods-tab").tab("show");
                this.getVendor();
              } else {
                this.$router.push({ name: "DataVendor" });
              }
            },
            onDismiss: () => {
              if (this.onAddGoods) {
                this.isEditable = false;
                this.onAddGoods = false;
                this.getVendor();
              } else {
                this.$router.push({ name: "DataVendor" });
              }
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },

    async onSubmit() {
      for (const key in this.rules) {
        if (key != "contact" && this.rules[key]) {
          if (this.rules[key]) {
            this.rules[key].changed = true;
          }
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success && !this.errorRekening) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      } else {
        this.isSubmit = false;
        setTimeout(() => {
          if ($("#pills-detail .form-error").length > 0) {
            $("#pills-detail-tab").tab("show");
          }
        }, 200);
      }
    },

    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail(id_contact) {
      this.$router.push({
        name: "ContactVendorDetail",
        params: {
          id_vendor: this.id_vendor,
          id_contact: id_contact,
        },
      });
      $('[data-toggle="tooltip"]').tooltip("hide");
    },
  },
  watch: {
    id_vendor: function (newVal) {
      this.id_vendor = newVal;
      this.getVendor();
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
}

.nav-pills .nav-item .nav-link.active[data-v-161fae0a]:after {
  border-radius: 5px !important;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.dropdown-item {
  color: #000 !important;
}

a.dropdown-item:focus,
a.dropdown-item:active,
a.dropdown-item.active {
  background: transparent;
  color: #000 !important;
}

.dropdown-menu {
  left: -14px !important;
}

.btn-download {
  padding: 8px 12px !important;
  font-size: 14px !important;
}
</style>
